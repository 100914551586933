import { computed, reactive } from '@vue/composition-api'
import { useValidation } from 'vue-composable'

import {
  dobValidation,
  incorporationDateValidation,
  expiryDateValidation,
} from '@/forms/validations/dateValidations'
import {
  useCorporateFieldPropsHelper,
  useCorporateFieldValidationHelper,
  useFieldComponentHelper,
} from '@/composables/useFormHelpers'

import AppDynamicFormField from '@/models/DynamicField/AppDynamicFormField'

import { useDynamicForm } from '@/forms/useDynamicForm'
import { useCorporateRegistrationStore } from '../stores/corporateRegistration'
import { useCompanyDetailsStore } from '@/stores/companyDetails'

const prefilledFields = [
  {
    fieldIds: ['registeredaddresscountry', 'tradingaddresscountry', 'contactaddresscountry'],
    disabled: true,
    getter: 'companyCountry',
  },
]

const getPrefilledFieldData = (fieldId) => {
  const companyDetailsStore = useCompanyDetailsStore()
  if (fieldId) {
    const fieldData = prefilledFields.find((item) => item.fieldIds.includes(fieldId))

    if (fieldData) {
      fieldData.value = companyDetailsStore[fieldData.getter]
      return fieldData
    }
  }
  return null
}

const createDynamicFormField = (field, stage) => {
  const corporateRegistrationStore = useCorporateRegistrationStore()
  let fieldData = getPrefilledFieldData(field.uniqueId)
  if (!fieldData) {
    fieldData = { value: '' }
  }
  const fieldValue = corporateRegistrationStore.getCorporateFormFieldValue(stage, field.id)
  if (fieldValue) {
    if (field.type === 'date') {
      fieldData.value = fieldValue.substr(0, 10)
    } else {
      fieldData.value = fieldValue
    }
  }
  if (fieldValue || field.id === 'phonenumber') {
    fieldData.value = fieldData.value || field.value
  }
  if (field.id === 'country') {
    field.type = 'xeCountry'
  }
  if (field.id === 'currencytype') {
    field.type = 'xeCurrency'
  }
  const appDynamicFormField = new AppDynamicFormField({
    field,
    value: fieldData.value,
    hidden: fieldData.hidden || false,
    disabled: fieldData.disabled || false,
    props: useCorporateFieldPropsHelper(field),
    component: useFieldComponentHelper(field.type),
  })

  return reactive(appDynamicFormField)
}

const getCustomFieldValidation = (fieldId) => {
  const items = [
    {
      ids: ['dateofbirth', 'dob'],
      validation: dobValidation('you'),
    },
    {
      ids: ['incorporationdate'],
      validation: incorporationDateValidation('date of incorporation'),
    },
    {
      ids: ['expiryDate'],
      validation: expiryDateValidation('date'),
    },
  ]

  const validation = items.find((item) => item.ids.includes(fieldId))
  if (!validation) {
    return {}
  }
  return validation.validation
}

export const createFieldValidation = (field) => {
  const validation = useCorporateFieldValidationHelper(field)
  const customValidations = getCustomFieldValidation(field.id)
  const validations = Object.assign(customValidations, validation)
  field.serverError = null
  validations['serverError'] = {
    $validator(v) {
      if (field.serverError) {
        return false
      }
      return true
    },
    $message: computed(() => {
      return field.serverError
    }),
  }
  return validations
}

export const useRegistrationForm = () => {
  const { isLoading, formValidation, formFields, hasFormFields, isFormInvalid } = useDynamicForm()
  const companyDetailsStore = useCompanyDetailsStore()

  const setup = (fields, stage) => {
    isLoading.value = true

    const formData = []
    const validationData = {}

    for (const field of fields) {
      if (field.id === 'province') {
        field.id = 'state'
      }
      if (
        field.id === 'registrationnumber' &&
        ['AU', 'NF'].includes(companyDetailsStore.companyCountry)
      ) {
        field.validations.map(function (element) {
          if (element.type === 'Regex') {
            element.value = '^[0-9]{1,11}$'
          }
        })
      }
      const formField = createDynamicFormField(field, stage)
      validationData[formField.name] = createFieldValidation(formField)

      if (field.id === 'country') {
        formData.unshift(formField)
      } else {
        formData.push(formField)
      }
    }

    formValidation.value = useValidation(validationData)

    for (const formField of formData) {
      formField.validation = formValidation.value[formField.name]
      if (formField.parent) {
        formField.parent = formData.find((item) => item.id === formField.parent)
      }
      if (formField.value) {
        formField.validation.$dirty = true
      }
    }

    isLoading.value = false
    formFields.value = formData
  }

  return {
    setup,
    hasFormFields,
    isFormInvalid,
    isLoading,
    formFields: computed(() => formFields.value),
  }
}
