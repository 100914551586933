import { camelCase } from 'lodash-es'
export default class AppDynamicFormField {
  constructor({ field, value, hidden, disabled, props, component, wrapperClass }) {
    this.value = value
    this.hidden = hidden
    this.disabled = disabled
    this.props = props
    this.component = component
    this.wrapperClass = wrapperClass

    const { id, label, showOnParentValues, parent, validations } = field
    this.id = id
    this.label = label
    this.name = camelCase(label)
    this.showOnParentValues = showOnParentValues
    this.parent = parent
    this.validation = validations
    this.serverError = []
  }

  isVisible() {
    if (this.hidden) {
      return false
    }

    if (this.parent) {
      const isVisible =
        this.showOnParentValues.indexOf(this.parent.value) !== -1
      if (this.value && !isVisible) {
        this.value = ''
      }
      return isVisible
    }

    return true
  }
}
