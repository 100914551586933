import { camelCase } from 'lodash-es'
import { helpers } from '@vuelidate/validators'
import { toRef } from '@vue/composition-api'

import AppDynamicFormField from '@/models/DynamicField/AppDynamicFormField'
import useFieldVisibilityHelper from './useFieldVisibilityHelper'

export default function (field) {
  const { label } = field
  let lowerCaseLabel = '';
  if(typeof label === 'string'){
    lowerCaseLabel = label.toLowerCase();
  }
  const isAppDynamicFormField = field instanceof AppDynamicFormField

  const validations = isAppDynamicFormField
    ? field.validation
    : field.validations

  const isVisible = () => {
    if (isAppDynamicFormField) {
      return field.isVisible()
    }
    return useFieldVisibilityHelper(field)
  }

  const isNotVisibleOrNotRequired = (val) => !isVisible() || !helpers.req(val)

  // Create validation object
  const validation = {
    $value: toRef(field, 'value'),
  }

  for (const validator of validations) {
    const type = camelCase(validator.type)
    const value = validator.value

    let $validator, $message

    // Lookup validation type and create validator
    if (type === 'required') {
      $validator = (v) => {
        // Short circuit validation if not visible
        if (!isVisible()) {
          return true
        }
        return helpers.req(v)
      }
      if (field.component.name === 'xeTextarea') {
        $message = `Please fill out this field`
      } else {
        $message = `Please enter ${lowerCaseLabel}`
      }
    } else if (type === 'minLength') {
      $validator = (v) => {
        if (isNotVisibleOrNotRequired(v)) {
          return true
        }
        return v.length >= parseInt(value)
      }
      $message = `${label} does not reach min length of ${value}`
    } else if (type === 'maxLength') {
      $validator = (v) => {
        if (isNotVisibleOrNotRequired(v)) {
          return true
        }
        return v.length <= parseInt(value)
      }
      $message = `${label} exceeded max length of ${value}`
    } else if (type === 'regex') {
      let validationRegex = new RegExp()
      try {
        validationRegex = new RegExp(value.pattern || value, 'u')
      } catch (e) {
        // TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        console.error(e)
      }
      $validator = (v) => {
        if (isNotVisibleOrNotRequired(v)) {
          return true
        }
        // Check if v is an array
        if (Array.isArray(v)) {
          // Test each element in the array
          return v.every((element) => validationRegex.test(element))
        }
        return validationRegex.test(v)
      }
      $message = `Please enter a valid ${lowerCaseLabel}`
    }

    // Add validator to validation
    if ($validator) {
      validation[type] = {
        $validator,
        $message: validator.message || $message,
      }
    }
  }

  return validation
}
