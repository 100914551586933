import { ref, computed } from '@vue/composition-api'

export const useDynamicForm = () => {
  const isLoading = ref(false)
  const formValidation = ref({})
  const formFields = ref([])

  const hasFormFields = computed(() => {
    if (isLoading.value) {
      return false
    }
    return formFields.value.length > 0
  })

  const isFormInvalid = computed(() => {
    if (hasFormFields.value) {
      return formValidation.value.$anyInvalid
    }
    return false
  })

  return {
    isLoading,
    formValidation,
    formFields,
    hasFormFields,
    isFormInvalid,
  }
}
