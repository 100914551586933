import { isValidDate } from '@/utils/date'

const isOver18 = (customText) => {
  return {
    $validator(v) {
      if (!isValidDate(v)) {
        return true
      }
      const today = new Date()
      const isAbove = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())
      return new Date(`${v}T00:00:00`) <= isAbove
    },
    $message: `${customText} must be at least 18 years old`,
  }
}

const isUnder120 = (customText) => {
  return {
    $validator(v) {
      if (!isValidDate(v)) {
        return true
      }
      const today = new Date()
      const isBelow = new Date(today.getFullYear() - 120, today.getMonth(), today.getDate())
      return new Date(`${v}T00:00:00`) > isBelow
    },
    $message: `${customText} must be under 120 years old`,
  }
}

const isPastDate = (customText) => {
  return {
    $validator(v) {
      if (!isValidDate(v)) {
        return true
      }
      const today = new Date()
      return new Date(v) <= today
    },
    $message: `The ${customText} must be a date in the past`,
  }
}

const isFutureDate = (customText) => {
  return {
    $validator(v) {
      if (!isValidDate(v)) {
        return true
      }
      const today = new Date()
      return new Date(v) >= today
    },
    $message: `The ${customText} must be a date in future`,
  }
}

const invalidDate = (customMessage) => {
  return {
    $validator(v) {
      if (!v) {
        return true
      }
      return v.length > 0 ? isValidDate(v) : true
    },
    $message: customMessage ? customMessage : 'Invalid date entered',
  }
}

export const dobValidation = (text) => {
  return {
    isOver18: isOver18(text),
    isUnder120: isUnder120(text),
    invalidDate: invalidDate(),
  }
}

export const incorporationDateValidation = (text) => {
  return {
    invalidDate: invalidDate(),
    isUnder120: isUnder120(text),
    isPastDate: isPastDate(text),
  }
}

export const expiryDateValidation = (text) => {
  return {
    invalidDate: invalidDate('Enter a valid date'),
    isFutureDate: isFutureDate(text),
  }
}
