export default function (field) {
  const { hidden, showOnParentValues } = field
  // Hide field if hidden
  if (hidden) {
    return false
  }

  // Check if field has parent
  const parent = field.getParent
  if (parent) {
    const visible = showOnParentValues.indexOf(parent().value) !== -1

    // Reset field value if model has value and field no longer visible
    if (!visible && field.value) {
      field.value = ''
    }
    return visible
  }

  return true
}
