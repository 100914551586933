export default function (type) {
  const components = {
    phoneNumber: () => import('@/components/AppInputPhone/AppInputPhone'),
    default: () => import('@/components/AppInputText/AppInputText'),
    searchableSelect: () => import('@/components/AppInputDropdown/AppInputDropdown'),
    date: () => import('@/components/AppInputDate/AppInputDate'),
    optionList: () => import('@/components/AppInputDropdown/AppInputDropdown'),
    country: () => import('@/components/AppInputCountry/AppInputCountry'),
    xeInputDropdown: () => import('@/components/XeInputDropdown/XeInputDropdown'),
    xeInputDropdownCustomComponent: () =>
      import('@/components/XeInputDropdownCustomComponent/XeInputDropdownCustomComponent'),
    xeCountry: () => import('@/components/XeInputCountry/XeInputCountry'),
    xeTextarea: () => import('@/components/XeTextarea/XeTextarea'),
    xeCurrency: () => import('@/components/XeInputCurrency/XeInputCurrency'),
    xeInputCheckboxList: () => import('@/components/XeInputCheckboxList/XeInputCheckboxList'),
    xeInputMultiselect: () => import('@/components/XeInputMultiselect/XeInputMultiselect'),
    xeMultipleChoiceButtonsWithCustomInput: () =>
      import(
        '@/components/XeMultipleChoiceButtonsWithCustomInput/XeMultipleChoiceButtonsWithCustomInput'
      ),
  }
  return components[type] || components.default
}
