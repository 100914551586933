import { parse, isValid } from 'date-fns'

export const isValidDate = (v) => {
  // Regular expression to check if the year part of the date is exactly 4 digits
  const yearRegex = /^\d{4}-\d{2}-\d{2}$/
  // First, check if the format of the date string matches 'yyyy-MM-dd'
  if (!yearRegex.test(v)) {
    return false
  }
  // Parse the date string
  const parsedDate = parse(v, 'yyyy-MM-dd', new Date())
  // Check if the parsed date is valid
  return isValid(parsedDate)
}

export const formatDateToLongString = (dateString) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  // Detect the date format by looking for which segment is likely the year
  const parts = dateString.split('-')
  const yearIndex = parts[0].length === 4 ? 0 : 2
  const monthIndex = 1
  const dayIndex = yearIndex === 0 ? 2 : 0

  // Extract the year, month, and day from the date string
  const year = parts[yearIndex]
  const month = parts[monthIndex]
  const day = parts[dayIndex]

  // Convert month from '01'-'12' to 'Jan'-'Dec' and day to number to remove leading zero
  const formattedDate = `${monthNames[parseInt(month, 10) - 1]} ${parseInt(day, 10)}, ${year}`

  return formattedDate
}
