import tradingVolumes from '@/data/api/volumeMockData.json'
import { isApacRegion } from '@/utils/i18n'
import { VOLUME_VALUE_APAC } from '@/constants/businessInformation'

export const setOptionValuesFromVolume = (country) => {
  // Uses default values if country does not exists in json file
  return tradingVolumes[isApacRegion(country)] || tradingVolumes.default
}

export const setVolumeLabel = (country, defaultLabel) => {
  return isApacRegion(country) === 'APAC' ? VOLUME_VALUE_APAC : defaultLabel
}
