import { reactive, ref } from '@vue/composition-api'
import heardAboutUsOptions from '@/data/api/getHeardAboutUsOptions.json'

import { useCompanyDetailsStore } from '@/stores/companyDetails'
import { useCountriesStore } from '@/stores/countries'
import { useDashboardStore } from '@/stores/dashboard'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { setOptionValuesFromVolume } from '@/helpers/businessInformation'

export default (field) => {
  const props = {}
  const companyDetailsStore = useCompanyDetailsStore()
  const countriesStore = useCountriesStore()
  const corporateRegistrationStore = useCorporateRegistrationStore()

  if (field.type === 'xeInputDropdownCustomComponent') {
    return createPropsForXeInputDropdownCustomComponent(field)
  }
  if (field.type === 'xeInputMultiselect') {
    return createPropsForXeInputMultiselect(field)
  }
  if (field.type === 'xeTextarea') {
    props.helperText = field.helperText
    props.placeholder = field.placeholder
    props.otherPlaceholder = field.otherPlaceholder
    if (field.options?.showCounter) {
      props.showCounter = field.options.showCounter
    }
    return reactive(props)
  }

  if (field.options && field.options.length > 0) {
    let options = field.options.filter((option) => option.id)
    options = options.map((option) => {
      return {
        value: option.id.toString(),
        text: option.name,
      }
    })
    props.options = ref(options)
  } else if (field.hasAsyncValues) {
    props.options = [{}]
    props.loading = false
  }

  props.placeholder = field.placeholder
  if (props.options && !props.placeholder) {
    props.placeholder = 'Select option'
  }

  if (field.type === 'phoneNumber') {
    props.options = corporateRegistrationStore.offeredCountriesDialingCodesOptions
    props.selectedOptionWithFlag = field.selectedOptionWithFlag
    props.areaCode = field.areaCode
  } else if (field.id === 'issuingCountry') {
    props.countries = corporateRegistrationStore.allCountryList.map((country) => ({
      id: country.code,
      text: country.name,
      value: country.code,
    }))
  } else if (field.type === 'country') {
    props.countries = corporateRegistrationStore.supportCountryList.map((country) => {
      return {
        id: country.code,
        text: country.name,
        value: country.code,
      }
    })
  } else if (field.id === 'state' || field.id === 'issuingState') {
    props.options = corporateRegistrationStore.stateList
  } else if (field.id === 'streettype') {
    props.options = corporateRegistrationStore.streetTypeList
  } else if (field.id === 'natureofbusiness') {
    props.options = corporateRegistrationStore.natureOfBusinessList
  } else if (field.id === 'country') {
    props.dropdownLabel = field.label
    props.countries = corporateRegistrationStore.supportCountryList
  } else if (field.id === 'currencytype') {
    props.dropdownLabel = field.label
    props.countries = corporateRegistrationStore.currencyTypeList
  } else if (field.id === 'companytype') {
    // TODO: set value for company types
    props.optionLabel = 'name'
    props.optionValue = 'name'
    props.options = companyDetailsStore.companyTypeList
  } else if (field.id === 'expectedannualtradingvolume') {
    // Assigns the value to dropdown
    props.options = setOptionValuesFromVolume(companyDetailsStore.companyCountry)
  } else if (field.id === 'expectedtotradein') {
    // TODO: hardcoded options, update when api is ready
    props.options = require('../../data/api/expectedToTradeInMockData.json')
  }

  if (props.options) {
    props.selectedDisplayField = 'text'
    props.selectedValueField = 'value'
  }

  if (field.type === 'xeInputDropdown') {
    props.dropdownLabel = field.label
    if (
      field.id === 'natureofbusiness' ||
      field.id === 'state' ||
      field.id === 'country' ||
      field.id === 'streettype'
    ) {
      props.optionLabel = 'name'
      props.optionValue = 'id'
    }
    if (field.id === 'issuingState') {
      props.optionLabel = 'name'
      props.optionValue = 'name'
    }
    if (field.id === 'currencytype') {
      props.optionLabel = 'name'
      props.optionValue = 'isoCode'
    }
    if (field.id === 'expectedannualtradingvolume') {
      props.optionLabel = 'text'
      props.optionValue = 'volume'
    }
    if (field.id === 'industry') {
      props.optionLabel = 'name'
      props.optionValue = 'id'
      props.options = corporateRegistrationStore.subIndustryList
    }
    if (field.id === 'expectedtotradein') {
      props.optionLabel = 'name'
      props.optionValue = 'expectedtotradein'
    }
    if (field.id === 'countryOfBirth') {
      props.options = corporateRegistrationStore.allCountryList
      props.optionLabel = 'name'
      props.optionValue = 'code'
    }
    if (field.id === 'nationality') {
      const countriesWithNationality = corporateRegistrationStore.allCountryList.filter(
        (country) => country.nationality
      )
      props.options = countriesWithNationality
      props.optionLabel = 'nationality'
      props.optionValue = 'nationality'
    }
  }

  if (
    field.type === 'date' &&
    (field.id === 'dateofbirth' ||
      field.id === 'incorporationdate' ||
      field.id === 'dob' ||
      field.id === 'expiryDate')
  ) {
    props.monthLabel = ''
    props.dayLabel = ''
    props.yearLabel = ''

    const country = companyDetailsStore.companyCountry
    const countryConfig = countriesStore.getCountryConfigByCountryCode(country)
    const countriesDefaultDateFormat = countryConfig?.dateFormat

    if (field.inputFormat) {
      props.inputFormat = field.inputFormat
    } else {
      props.inputFormat = countriesDefaultDateFormat
    }
  }

  if (field.type === 'xeInputCheckboxList') {
    props.data = field.checkboxOptions
    props.hideBorder = field.hideBorder
    props.customInput = field.customInput
    props.helperText = field.helperText
  }

  if (field.type === 'xeMultipleChoiceButtonsWithCustomInput') {
    props.data = field.options
    props.subLabel = field.subLabel
  }

  return reactive(props)
}

const createPropsForXeInputDropdownCustomComponent = (field) => {
  const dashboardStore = useDashboardStore()

  const props = {}
  // move "Other" to the end of array
  let fieldOptions = []
  if (field.id === 'purpose') {
    fieldOptions = dashboardStore.purposeOfPayments.filter((field) => field.name !== 'Other')
    fieldOptions.push(field.options.find((field) => field.name === 'Other'))
  } else if (field.id === 'heardAboutUs') {
    fieldOptions = heardAboutUsOptions
  } else {
    fieldOptions = field.options
  }
  fieldOptions.push(
    fieldOptions
      .splice(
        fieldOptions.findIndex((option) => option.name === 'Other'),
        1
      )
      .pop()
  )
  const options = fieldOptions.map((option) => {
    return {
      value: option.name,
      text: option.name,
      allowFreeText: option.allowFreeText,
      allowTextBox: option.allowTextBox,
      freeTextLabel: option.freeTextLabel,
      freeTextPlaceholder: option.freeTextPlaceholder,
      validation: option.validation,
      type: option.type,
      followUps: option.followUps,
    }
  })
  props.options = ref(options)
  props.placeholder = 'Select option'
  props.otherPlaceholder = field.otherPlaceholder
  props.selectedDisplayField = 'text'
  props.selectedValueField = 'value'
  props.dropdownLabel = field.label
  props.optionLabel = 'text'
  props.optionValue = 'value'

  return reactive(props)
}

const createPropsForXeInputMultiselect = (field) => {
  const props = {}
  const corporateRegistrationStore = useCorporateRegistrationStore()

  let fieldOptions = []
  if (field.id === 'destinationCountries') {
    fieldOptions = corporateRegistrationStore.allCountryList
  } else {
    fieldOptions = field.options
  }

  const options = fieldOptions.map((option) => {
    return {
      value: option.code,
      text: option.name,
    }
  })
  props.options = ref(options)
  props.placeholder = field.placeholder
  props.multiSelectLabel = field.label

  // default props for XeInputMultiselect
  props.optionLabel = 'text'
  props.optionValue = 'value'
  props.type =
    field.id === 'destinationCountries' ? ['flag', 'chipCloseButton'] : ['chipCloseButton']
  props.display = 'chip'

  // Retrieve maxSelectedItems validation from field.validations
  const validation = field.validations.find((validation) => validation.type === 'MaxLength')
  if (validation) {
    props.maxSelectedItems = validation.value
    props.maxSelectedItemsMessage = validation.message
  }

  return reactive(props)
}
