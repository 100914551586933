<template>
  <Component
    :is="field.component"
    v-model="field.value"
    :label="field.label"
    v-bind="field.props"
    :validation="field.validation"
    :disabled="disabled || field.disabled"
    :serverError="field.serverError"
    :class="field.wrapperClass"
    v-on="field.listeners"
    @custom-event="handleCustomEvent"
  />
</template>

<script>
import AppDynamicFormField from '@/models/DynamicField/AppDynamicFormField'

export default {
  name: 'AppDynamicFormField',
  components: {},
  emits: [],
  props: {
    field: {
      type: AppDynamicFormField,
      default: () => {},
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleCustomEvent(event, data) {
      this.$emit(event, data)
    },
  },
}
</script>
<style lang="postcss" scoped></style>
